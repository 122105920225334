import SwiperCore, { Navigation, FreeMode } from 'swiper'
import PromotionOfferInfo from './promotionOfferInfo'
import Router, { useRouter } from 'next/router'
import { COUPON_IMG_BASE_URL } from '@framework/utils/constants'
import { useUI } from '@components/ui'
import {
  handleLoginAttemptGAEvent,
  handleProductClickGAEvent,
  selectPromotionGAEvent,
} from './helper'
import { openLoginView } from '@framework/utils/app-util'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EmptyGuid } from '@components/utils/constants'
import 'swiper/css'
import 'swiper/css/navigation'
import useCart from '@components/services/cart'
import { viewCoupons } from './eventsHelper'
import { useEffect } from 'react'
import { getSessionItem } from '@components/utils/sessionStorage'
import { LocalStorage } from '@components/utils/payment-constants'

SwiperCore.use([Navigation])

const PromoList = (props: any) => {
  const { basketPromos, onPromoApply, appliedList, previousPath, deviceInfo } =
    props
  const { setSidebarView, user, cartItems, isGuestUser } = useUI()
  const { getCart } = useCart()
  const router = useRouter()
  const getRupeeValue = (message: string) => {
    return +message?.split('₹')?.[1]?.split(' ')?.[0]
  }

  useEffect(() => {
    if (
      basketPromos?.applicablePromotions?.length > 0 ||
      basketPromos?.availablePromotions?.length > 0
    ) {
      viewCoupons(basketPromos?.applicablePromotions, cartItems, user, 'Cart')
    }
  }, [])

  const promoListTransformer = (isUserLoggedIn: boolean) => {
    const applicablePromotions = basketPromos?.applicablePromotions?.map(
      (item: any) => {
        const info9 = item?.additionalInfo9 && JSON.parse(item.additionalInfo9)
        const balanceAmount: number = getRupeeValue(item.croMessage)
        const isLoginBtn = info9?.cta
          ? info9?.cta?.toLowerCase() === 'login now'
          : null
        return {
          promoName: item.code,
          promoDesc: item?.croSuccessMessage || item.additionalInfo6,
          promoDetails: item,
          btnText: 'Apply',
          isApplicable: true,
          progressPercent:
            isNaN(balanceAmount) && info9?.maxval
              ? 100
              : item?.croMessage && info9?.maxval
              ? Math.floor((balanceAmount / info9?.maxval) * 100)
              : null,
          img: info9?.img ? `${COUPON_IMG_BASE_URL}${info9?.img}` : null,
          onSubmit: () => {
            if (isLoginBtn && !isUserLoggedIn) {
              handleLoginAttemptGAEvent(user, previousPath)
              openLoginView(router?.asPath)
              localStorage.setItem(LocalStorage.Key.ROUTE_REDIRECTION_PATH, router.asPath)
              localStorage.setItem('saveGKwikAddress', 'true')
              setSidebarView('NEW_LOGIN_VIEW')
            } else {
              selectPromotionGAEvent(cartItems, item?.name, item?.code, user)
              onPromoApply('apply', item.code)
            }
          },
        }
      }
    )
    const availablePromotions = basketPromos?.availablePromotions?.map(
      (item: any) => {
        const info9 = item?.additionalInfo9 && JSON.parse(item.additionalInfo9)
        const balanceAmount: number =
          info9?.maxval - getRupeeValue(item.croMessage)
        const isLoginBtn = info9?.cta
          ? info9?.cta?.toLowerCase() === 'login now'
          : null
        return {
          promoName: item.code,
          promoDesc: item?.croMessage || item.additionalInfo6,
          promoDetails: item,
          btnText:
            info9?.cta?.toLowerCase() === 'login now' && isUserLoggedIn
              ? null
              : info9?.cta
              ? `${info9?.cta} →`
              : null,
          isApplicable: false,
          progressPercent:
            isNaN(balanceAmount) && info9?.maxval
              ? 100
              : item?.croMessage && info9?.maxval
              ? Math.floor((balanceAmount / info9?.maxval) * 100)
              : null,
          img: info9?.img ? `${COUPON_IMG_BASE_URL}${info9?.img}` : null,
          onSubmit: () => {
            if (isLoginBtn) {
              handleLoginAttemptGAEvent(user, previousPath)
              openLoginView(router?.asPath)
              localStorage.setItem(LocalStorage.Key.ROUTE_REDIRECTION_PATH, router.asPath)
              localStorage.setItem('saveGKwikAddress', 'true')
              setSidebarView('NEW_LOGIN_VIEW')
            } else {
              const couponData = {
                couponName: item?.code,
                destinationUrl: info9?.page,
              }
              handleProductClickGAEvent(user, couponData)
              info9?.page && Router.push(info9?.page)
            }
          },
        }
      }
    )
    const newListApplicablePromotions =
      applicablePromotions?.length > 0
        ? [...applicablePromotions]?.sort(
            (a: any, b: any) => b?.progressPercent - a?.progressPercent
          )
        : []
    const newListAvailablePromotions =
      availablePromotions?.length > 0
        ? [...availablePromotions]?.sort((a, b) => {
            if (b.progressPercent !== a.progressPercent) {
              return b.progressPercent - a.progressPercent
            }
            if (a.promoName === 'SAVE-EXTRA' && b.promoName !== 'SAVE-EXTRA') {
              return -1
            }
            if (b.promoName === 'SSAVE-EXTRA' && a.promoName !== 'SAVE-EXTRA') {
              return 1
            }
            return 0
          })
        : []
    return [...newListApplicablePromotions, ...newListAvailablePromotions]
  }
  const sessionStoredUser = getSessionItem('isUserAlreadyExist')
  const isUserAlreadyExist = sessionStoredUser
    ? JSON.parse(sessionStoredUser)
    : true
  const isUserLoggedIn =
    !isGuestUser && user?.userId && user?.userId !== EmptyGuid
  const promoListTransformerVal = promoListTransformer(isUserLoggedIn)
  return (
    <div className="flex w-full des-hide-top">
      <Swiper
        slidesPerView={1.25}
        spaceBetween={5}
        navigation={true}
        loop={false}
        freeMode={true}
        modules={[FreeMode]}
        breakpoints={{
          320: {
            slidesOffsetAfter: 18,
            slidesOffsetBefore: 18,
          },
          640: {
            slidesOffsetAfter: 35,
            slidesOffsetBefore: 35,
          },
          768: {
            slidesOffsetAfter: 35,
            slidesOffsetBefore: 35,
          },
          1024: {
            slidesOffsetAfter: 35,
            slidesOffsetBefore: 35,
          },
        }}
        slidesOffsetAfter={18}
        slidesOffsetBefore={18}
      >
        <div role="list">
          {promoListTransformerVal.map((item) => {
            const checkNotApplied = appliedList?.find(
              (val: any) => val?.promoCode === item?.promoName
            )
            if (
              checkNotApplied
              // || (isUserLoggedIn && isUserAlreadyExist &&item?.promoName === 'WELCOME10')
            ) {
              return null
            }
            return (
              <SwiperSlide key={`promo_${item.promoName}`}>
                <PromotionOfferInfo
                  {...item}
                  deviceInfo={deviceInfo}
                  previousPath={previousPath}
                />
              </SwiperSlide>
            )
          })}
        </div>
      </Swiper>
    </div>
  )
}

export default PromoList
