import { ArrowLeft } from '@components/icons'
import {
  COUPON_CODE,
  EmptyGuid,
  PROMO_ERROR_MESSAGE_WITH_LOGIN,
  SHOW_APPLY_COUPON_SECTION,
} from '@components/utils/constants'
import {
  APPLY_PROMOTION,
  GENERAL_APPLY_TEXT,
  PROMO_ERROR,
} from '@components/utils/textVariables'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { getOfferTexts } from '../helper'
import { useUI } from '@components/ui'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import Router, { useRouter } from 'next/router'
import { filterJusPayOffers } from '@framework/utils/juspay-util'
import { openLoginView } from '@framework/utils/app-util'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { handleGAEventHaveReferralCode } from '../eventsHelper'
import { APPLY_JUSPAY_OFFERS_DISABLED } from '@framework/utils/constants'
import { IDeviceInfo } from '@components/ui/context'
import MultiSelectPromos from './MultiSelectPromos'
import SingleSelectPromos from './SingleSelectPromos'
import AvailablePromotions from './AvailablePromotions'
import { IBasketPromos } from '@components/common/utils/models/cart/convenience.model'
import { handleViewCartGAEvents } from '@components/MobileCart/helper'
import { LocalStorage } from '@components/utils/payment-constants'

interface IProps {
  isShowCoupon: boolean
  handleCloseCoupon: (val: boolean) => void
  deviceInfo: IDeviceInfo
  handleChange: (val: string) => void
  items: any
  handleSubmit: (method: string, promoCode: string, isMulti?: boolean) => void
  setError: (val: boolean) => void
  setAppliedBasketPromo: (val: any) => void
  setCouponApplied: (val: boolean) => void
  paymentOffers: any
  basketPromos: IBasketPromos
  value: string
  isAppLoading: boolean
  applyCouponInput: (val1: string, val2: any) => void
  error: boolean
  previousPath: string
  renderErrorMessage: () => JSX.Element | null
}

const CouponsPanelPopup = (props: IProps) => {
  const {
    isShowCoupon,
    handleCloseCoupon,
    deviceInfo,
    handleChange,
    items,
    handleSubmit,
    setError,
    setAppliedBasketPromo,
    setCouponApplied,
    paymentOffers,
    basketPromos,
    value,
    isAppLoading,
    applyCouponInput,
    error,
    previousPath,
    renderErrorMessage,
  } = props

  const [multiPromo, setMultipromo] = useState<any[]>([])

  const { isMobile, isIPadorTablet } = deviceInfo
  const trackMoEngageEvent = useMoEngageEvent()
  const { asPath } = useRouter()

  const { cartItems, isGuestUser, user, setSidebarView, setLoginCustomTitle } =
    useUI()

  const isUserLoggedIn =
    !isGuestUser && user?.userId && user?.userId !== EmptyGuid

  let deviceCheck = ''
  if (isMobile || isIPadorTablet) {
    deviceCheck = 'Mobile'
  } else {
    deviceCheck = 'Desktop'
  }

  const applyMultiPromo = async () => {
    try {
      if (multiPromo?.length > 0) {
        const applyMultiPromoResult = await Promise.all(
          multiPromo.map(async (p: any) => {
            const thisPromoApplyResult: any = await handleSubmit(
              'apply',
              p?.code,
              !!(multiPromo?.length > 1)
            )
            if (!thisPromoApplyResult) setError(true)
          })
        )
        if (applyMultiPromoResult) {
          setMultipromo([])
          handleCloseCoupon(false)
        }
      }
    } catch (err) {
      console.error(':: error ', err)
    }
  }

  const applyCoupon = async (promo: any, ev: any = null) => {
    const applyPromoResult: any = await handleSubmit('apply', promo?.code)

    if (applyPromoResult) {
      setAppliedBasketPromo(promo)
      handleCloseCoupon(false)
      setTimeout(() => {
        setCouponApplied(true)
      }, 1000)
    }
  }

  const handleMultiPromo = (promo: any) => {
    if (multiPromo.includes(promo))
      setMultipromo(multiPromo?.filter((p: any) => p !== promo))
    else setMultipromo([...multiPromo, promo])

    let promoDiscount = ''
    if (promo?.promoType == 1) {
      promoDiscount = promo?.additionalInfo1
    }
    if (
      promo?.promoType == 2 ||
      promo?.promoType == 4 ||
      promo?.promoType == 9
    ) {
      promoDiscount = 'FREE GIFT ITEM'
    }

    if (promo?.promoType == 16) {
      promoDiscount = '50% OFF on 3rd Item'
    }

    recordGA4Event(window, GA_EVENT.OFFER_POPUP, {
      offer_module: promo.code,
      current_page: 'PDP',
      offer_details: promo.name,
    })

    trackMoEngageEvent(
      MO_ENGAGE_EVENT.PDP_ORANGE_OFFER_MODAL,
      {
        URL: window?.location?.href,
        Product_name: items?.lineItems[0]?.name,
        Product_category: items?.lineItems[0]?.categoryItems[1]?.categoryName,
        Product_category_2: items?.lineItems[0]?.categoryItems[2]?.categoryName,
      },
      window
    )

    trackMoEngageEvent(
      MO_ENGAGE_EVENT.CART_COUPON,
      {
        URL: window?.location?.href,
        label: promo?.name,
        discount_applied: items?.discount?.raw?.withTax,
      },
      window
    )
  }

  const backGAEvent = () => {
    recordGA4Event(window, GA_EVENT.BACK_BUTTON, {
      current_page: 'View Coupon',
      previous_page: 'cart',
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }

  const handleApplyReferralCode = () => {
    handleGAEventHaveReferralCode(user)
    if (isUserLoggedIn) {
      Router?.push(
        {
          pathname: `${asPath}#referralOpen`,
        },
        `${asPath}#referralOpen`,
        {
          shallow: true,
          scroll: false,
        }
      )
    } else {
      setLoginCustomTitle('Login to apply the Referral Code')
      openLoginView(asPath)
      setTimeout(() => {
        setSidebarView('LOGIN_VIEW')
      }, 1000)
    }
  }

  const viewCart = () => {
    handleViewCartGAEvents(cartItems, deviceCheck, user, previousPath)
  }

  const getPaymentOffers = () => {
    return filterJusPayOffers(paymentOffers)
  }

  const multiSelectPromo = basketPromos?.applicablePromotions?.filter(
    (x: any) => x?.useWithOtherDiscountCode == 3
  )

  const singleSelectPromo = basketPromos?.applicablePromotions?.filter(
    (x: any) =>
      x?.useWithOtherDiscountCode == 1 ||
      x?.useWithOtherDiscountCode == 2 ||
      x?.useWithOtherDiscountCode == 4
  )

  const enableHtmlScroll = () => {
    const element: any = document.getElementsByTagName('html')[0]
    if (element) {
      element.classList.add('overlow-y-auto-p')
    }
  }

  const handleLogin = () => {
    localStorage.setItem(LocalStorage.Key.ROUTE_REDIRECTION_PATH,asPath);
    openLoginView(asPath)
    setSidebarView('NEW_LOGIN_VIEW')
    enableHtmlScroll()

    recordGA4Event(window, GA_EVENT.LOGIN_ATTEMPT, {
      current_page: 'View Coupon',
      previous_page: 'cart',
      address_source: 'Damensch'
    })
  }

  return (
    <Transition.Root show={isShowCoupon} as={Fragment}>
      <Dialog as="div" className="relative z-99" onClose={handleCloseCoupon}>
        <div className="fixed inset-0 left-0 bg-black/[.5]" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none bottom-to-top">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-400"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-400"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-md pointer-events-auto width-cart">
                  <div className="relative flex flex-col h-full overflow-y-auto bg-white shadow-xl z-99">
                    <div className="sticky top-0 z-10 px-4 py-4 bg-white border-b border-gray-200 sm:px-6 left-1">
                      <div className="flex">
                        <button
                          type="button"
                          className="mr-2 text-black rounded-md outline-none hover:text-gray-500"
                          onClick={() => {
                            handleCloseCoupon(false)
                            backGAEvent()
                            viewCart()
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <ArrowLeft
                            className="relative top-0 w-6 h-6"
                            aria-hidden="true"
                          />
                        </button>
                        <h3 className="font-bold text-20 xs-text-16 text-dark dark:text-black">
                          Apply Coupons
                        </h3>
                         {!isUserLoggedIn && (
                          <button 
                          className="text-base font-inter text-orange ml-auto"
                          onClick={() =>  handleLogin() }
                          >
                           Login
                          </button>
                         )}                 
                      </div>
                      {SHOW_APPLY_COUPON_SECTION &&
                        (isMobile || isIPadorTablet) && (
                          <div className="flex items-center pt-2 my-2">
                            <div className="w-full">
                              <div className="flex flex-col">
                                <div className="flex items-center justify-center w-full border border-gray-100 bg-dark-grey hover:border-orange-400">
                                  <input
                                    name={'promotion-code'}
                                    placeholder={APPLY_PROMOTION}
                                    onChange={(e: any) => handleChange(e)}
                                    value={value}
                                    className="w-full min-w-0 px-4 py-3 text-gray-900 placeholder-gray-500 bg-transparent appearance-none focus:outline-none"
                                  />

                                  <button
                                    onClick={async () =>
                                      !isAppLoading &&
                                      (await applyCouponInput(
                                        'applyInput',
                                        value
                                      ))
                                    }
                                    type="button"
                                    title={GENERAL_APPLY_TEXT}
                                    disabled={isAppLoading}
                                    className={`max-w-xs flex-1 ml-5 py-2 px-4 flex items-center bg-transparent border-transparent justify-center font-medium hover:text-orange-500 text-orange-500 hover:bg-transparent sm:w-full`}
                                  >
                                    {GENERAL_APPLY_TEXT}
                                  </button>
                                </div>
                              </div>
                              {renderErrorMessage()}
                            </div>
                          </div>
                        )}
                      <div className="flex flex-col justify-start my-0 sm:hidden sm:my-0">
                        {cartItems.promotionsApplied?.length
                          ? cartItems.promotionsApplied.map(
                              (promo: any, key: number) => {
                                return (
                                  <>
                                    <div
                                      className="flex justify-between gap-4 pt-2 my-1"
                                      key={key}
                                    >
                                      <div className="flex">
                                        <h5 className="font-medium uppercase text-primary dark:text-black text-14 xs-text-14">
                                          {' '}
                                          {promo.promoCode}
                                        </h5>
                                      </div>
                                      <div className="flex justify-end">
                                        <h5 className="font-medium uppercase text-14 xs-text-14 text-emerald-600">
                                          {promo?.discountAmt?.raw?.withTax >
                                          0 ? (
                                            <>
                                              <span>
                                                -
                                                {
                                                  promo?.discountAmt?.formatted
                                                    ?.withTax
                                                }
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span>Free Gift Added</span>
                                            </>
                                          )}
                                          {!promo?.autoApply && (
                                            <a href="javascript: void(0);">
                                              <span
                                                className="relative ml-2 sprite-icon cross-icon top-0.5"
                                                onClick={() =>
                                                  handleSubmit(
                                                    'remove',
                                                    promo.promoCode
                                                  )
                                                }
                                              ></span>
                                            </a>
                                          )}
                                        </h5>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                            )
                          : null}
                      </div>
                    </div>
                    <div className="p-6 pb-0w-full ">
                      <button
                        disabled={isAppLoading}
                        className="w-full text-orange border-2 border-sm p-4 font-semibold text-sm"
                        onClick={() =>
                          !isAppLoading && handleApplyReferralCode()
                        }
                      >
                        Have a referral code? Apply now
                      </button>
                    </div>
                    {basketPromos?.applicablePromotions?.length > 0 && (
                      <div className="p-6">
                        <div className="flex flex-col w-full">
                          {/* MULTI-SELCET PROMOTION WITH useWithOtherDiscountCode=3 */}
                          <MultiSelectPromos
                            multiSelectPromo={multiSelectPromo}
                            multiPromo={multiPromo}
                            handleMultiPromo={handleMultiPromo}
                          />

                          {/* INDIVIDUAL PROMOTION WITH useWithOtherDiscountCode=1 OR 2 */}
                          <SingleSelectPromos
                            singleSelectPromo={singleSelectPromo}
                            isAppLoading={isAppLoading}
                            applyCoupon={applyCoupon}
                          />

                          {!APPLY_JUSPAY_OFFERS_DISABLED &&
                            getPaymentOffers()?.length > 0 && (
                              <div className="flex flex-col mt-6">
                                <div className="grid grid-cols-1">
                                  <div>
                                    <h4 className="text-lg font-semibold text-dark-brown">
                                      Payment Offers
                                    </h4>
                                  </div>

                                  {getPaymentOffers()?.map(
                                    (offer: any, idx: number) => (
                                      <div
                                        key={idx}
                                        className="mt-3 border border-gray-200"
                                      >
                                        <div className="grid grid-cols-12">
                                          <div className="col-span-12 p-4">
                                            <div className="flex justify-between">
                                              <h3 className="mb-1 text-sm font-semibold text-black uppercase">
                                                {offer?.offer_code}
                                              </h3>
                                            </div>
                                            <div className="flex flex-col">
                                              <p className="text-xs font-medium text-emerald-500">
                                                {
                                                  offer?.offer_description
                                                    ?.title
                                                }
                                              </p>
                                            </div>
                                            <div className="flex flex-col pt-4 mt-4 border-t border-gray-200 border-dotted px">
                                              <p className="text-xs font-normal text-gray-400">
                                                {getOfferTexts(offer, cartItems)
                                                  ?.length > 0 && (
                                                  <>
                                                    {getOfferTexts(
                                                      offer,
                                                      cartItems
                                                    )?.map(
                                                      (
                                                        text: string,
                                                        idx: number
                                                      ) => (
                                                        <span key={idx}>
                                                          {text}
                                                        </span>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    {multiPromo?.length > 0 ? (
                      <div className="fixed inset-x-0 bottom-0 z-[99999] flex flex-col w-full px-6 py-4 bg-white">
                        <button
                          onClick={() => !isAppLoading && applyMultiPromo()}
                          type="button"
                          disabled={isAppLoading}
                          className="w-full text-white bg-black hover:bg-gray-800 btn-basic-property"
                        >
                          {`Apply ${multiPromo?.length} ${
                            multiPromo?.length > 1 ? 'Coupons' : 'Coupon'
                          }`}
                        </button>
                      </div>
                    ) : null}

                    <AvailablePromotions
                      basketPromos={basketPromos}
                      isAppLoading={isAppLoading}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CouponsPanelPopup
