import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { Cross, NewCouponIcon } from '@components/icons'
import { IPromotion } from './helpers/pomotions.model'
import { parseAdditionalInfo9 } from './ProductListStickyBar'
import { useRouter } from 'next/router'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { getCurrentPage } from '@framework/utils/app-util'
import { EmptyGuid } from '@components/utils/constants'
import { useUI } from '@components/ui'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { openLoginView } from '@framework/utils/app-util'
import { handleLoginAttemptGAEvent } from '@components/cart/Promotions/helper'
import { LocalStorage } from '@components/utils/payment-constants'

interface IProps {
  promotionData: IPromotion | null
  onClose: () => void
  deviceInfo: any
  previousPath: string
}

export default function PromotionDetailPopup({
  promotionData,
  onClose,
  deviceInfo,
  previousPath,
}: IProps) {
  const router = useRouter()
  const [isCopied, showCopied] = useState(false)
  const { user, setSidebarView, isGuestUser } = useUI()
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()

  const { isMobile, isIPadorTablet } = deviceInfo
  let device = ''
  if (isMobile || isIPadorTablet) {
    device = 'Mobile'
  } else {
    device = 'Desktop'
  }
  const isUserLoggedIn =
    !isGuestUser && user?.userId && user?.userId !== EmptyGuid

  useEffect(() => {
    promotionPopupOpen()
  }, [])

  const handleCopyCouponCode = (couponCode: string) => {
    navigator.clipboard.writeText(couponCode)
    showCopied(true)
    setTimeout(() => {
      showCopied(false)
    }, 3000)
  }

  const closeGAEvent = () => {
    trackGoogleAnalyticsEvent(
      GA_EVENT.CLOSE_BUTTON,
      {
        previous_page: 'Cart',
        current_page: 'promotion popup',
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
      window
    )
  }

  const promotionPopupOpen = () => {
    trackGoogleAnalyticsEvent(
      GA_EVENT.PROMOTION_POPUP_OPEN,
      {
        current_page: getCurrentPage(),
        promotion_promocode: promotionData?.code,
        coupon_name: promotionData?.name,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
      window
    )
  }

  const copyCouponCodeGAEvent = () => {
    trackGoogleAnalyticsEvent(
      GA_EVENT.COPY_COUPON_CODE,
      {
        current_page: 'promotion popup',
        promotion_promocode: promotionData?.code,
        coupon_name: promotionData?.name,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        device: device,
      },
      window
    )
  }

  const viewProductClickGAEvent = (url: string) => {
    trackGoogleAnalyticsEvent(
      GA_EVENT.VIEW_PRODUCTS_CLICK,
      {
        current_page: 'promotion popup',
        destination_url: url,
        coupon_name: promotionData?.name,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
      window
    )
  }

  const parsedData = parseAdditionalInfo9(promotionData?.additionalInfo9)
  return (
    <Transition show={!!promotionData?.code} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-99"
        onClose={() => {
          onClose()
          closeGAEvent()
        }}
      >
        <div className="fixed inset-0 left-0 bg-orange-900/20" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`fixed ${
                isMobile
                  ? 'top-auto bottom-0'
                  : 'inset-y-0 w-full flex items-center justify-center'
              } right-0 flex max-w-full pl-0 pointer-events-none bottom-to-top sm:pl-0`}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-400"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-400"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className="w-screen max-w-md pointer-events-auto"
                  onClick={(event: any) => {
                    event.stopPropagation()
                    onClose()
                  }}
                >
                  <div
                    className={`relative z-50 flex flex-col bg-white shadow-xl cvv-mobile-position-plp sm:bg-white ${
                      isMobile ? 'mob-width-full' : 'w-[70%]'
                    }`}
                    onClick={(event: any) => {
                      event.stopPropagation()
                    }}
                  >
                    <div className="absolute top-[-50px] right-[-20px] h-16 w-16">
                      <button
                        type="button"
                        className="mr-2 text-black rounded-md outline-none hover:text-gray-500"
                        onClick={() => {
                          onClose()
                          closeGAEvent()
                        }}
                      >
                        {/* <span className="sr-only">Close panel</span> */}
                        <Cross
                          className="relative w-8 h-8 sm:w-8 sm:h-8 sm:top-1 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                    <div className="relative z-10 px-5 py-4 border-b-[6px] border-gray-200  sm:relative ">
                      <div className="grid grid-cols-6 gap-4 w-full relative">
                        <div className="col-start-1 col-end-1 w-full max-h-6">
                          {parseAdditionalInfo9(promotionData?.additionalInfo9)
                            .img ? (
                            <img
                              src={
                                parseAdditionalInfo9(
                                  promotionData?.additionalInfo9
                                ).img
                              }
                              alt={promotionData?.code}
                              height="24px"
                              width="24px"
                              className="h-[28px] object-contain"
                            />
                          ) : (
                            <NewCouponIcon width="24" height="24" />
                          )}
                        </div>
                        <div className="col-start-2 col-end-7 w-full absolute left-[-25px]">
                          <h3 className="font-semibold text-16 sm-text-16 text-black">
                            {promotionData?.code}
                          </h3>
                        </div>
                        <div className="col-end-8 col-span-3 text-right relative">
                          <div
                            className={`bg-black px-2 py-1 promo-copied text-xs capitalize text-white text-center rounded-md  ${
                              isCopied ? 'block' : 'hidden'
                            }`}
                            style={{ top: '-25px', right: '-12px' }}
                          >
                            Copied!
                          </div>
                          <p
                            className="text-10 font-medium sm-text-10 text-[#2510008f] absolute top-[2px] right-0"
                            onClick={() => {
                              handleCopyCouponCode(promotionData?.code ?? '')
                              copyCouponCodeGAEvent()
                            }}
                          >
                            COPY CODE
                            <i className="sprite-icon sprite-copy" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="relative z-50 flex flex-col h-full overflow-y-auto bg-white">
                      <div className="px-5 pt-1 overflow-y-auto">
                        <div className="flex flex-col text-12 sm-text-12 text-[#251000] mb-2 mt-2">
                          {promotionData?.additionalInfo6}
                        </div>
                        {parsedData?.cta?.toLowerCase() == 'login now' &&
                          !isUserLoggedIn && (
                            <div
                              className="text-16 inline-block text-[#EB5B25] font-bold"
                              onClick={(event) => {
                                event.stopPropagation()
                                handleLoginAttemptGAEvent(user, previousPath)
                                openLoginView(router?.asPath)
                                localStorage.setItem(
                                  LocalStorage.Key.ROUTE_REDIRECTION_PATH,
                                  router.asPath
                                )
                                localStorage.setItem('saveGKwikAddress', 'true')
                                setSidebarView('NEW_LOGIN_VIEW')
                                onClose()
                              }}
                            >
                              {parsedData.cta}
                              <span className="ml-2">→</span>
                            </div>
                          )}
                        {parsedData.cta && parsedData.page && (
                          <div
                            className="text-16 inline-block text-[#EB5B25] font-bold"
                            onClick={(event) => {
                              event.stopPropagation()
                              viewProductClickGAEvent(parsedData.page)
                              router.push(parsedData.page)
                              onClose()
                            }}
                          >
                            {parsedData.cta}
                            <span className="ml-2">→</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-5 pb-5 mt-4">
                      {promotionData?.additionalInfo5 && (
                        <img
                          src={promotionData?.additionalInfo5}
                          alt={promotionData?.name}
                          className="max-h-[500px]"
                        />
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
