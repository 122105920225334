import { Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import axios from '@services/axiosInterceptorInstance'
import { Cross } from '@components/icons'
import { LoadingDots, useUI } from '@components/ui'
import { EmptyGuid, NEXT_CREATE_WISHLIST } from '@components/utils/constants'
import { CLOSE_PANEL } from '@components/utils/textVariables'
import { Dialog, Transition } from '@headlessui/react'
import { isMobileOnly } from 'react-device-detect'
import { handleAllEvents } from './helper'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import { openLoginView } from '@framework/utils/app-util'
import { LocalStorage } from '@components/utils/payment-constants'

declare const AF: any

interface IRemoveItemPopup {
  isRemoveProdConfirmationOpen: boolean
  isLoading: any
  handleCloseRemoveModal: (val: boolean) => void
  setIsLoading: (props: any) => void
  handleItem: (
    product: any,
    name: string,
    bool?: boolean,
    qty?: number,
    page?: string
  ) => void
  removedProduct: Array<any> | any
  isChannelStore?: boolean
}

const RemoveItemPopup = (props: IRemoveItemPopup) => {
  const {
    isRemoveProdConfirmationOpen,
    isLoading,
    handleCloseRemoveModal,
    setIsLoading,
    handleItem,
    removedProduct,
    isChannelStore,
  } = props

  const { user, closeSidebar, setSidebarView, addToWishlist, cartItems } =
    useUI()
  const router = useRouter()
  const trackMoEngageEvent = useMoEngageEvent()

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      // Check if the pressed key is the Escape key
      if (event.key === 'Escape') {
        // Prevent default behavior
        event.preventDefault()
        // Stop event propagation to prevent it from reaching Headless UI components
        event.stopPropagation()
      }
    })
    return () => {
      document.removeEventListener('keydown', () => {})
    }
  }, [])

  const enableHtmlScroll = () => {
    const element: any = document.getElementsByTagName('html')[0]
    element.classList.add('overlow-y-auto-p')
  }

  const insertToLocalWishlist = (product: any) => {
    addToWishlist(product)
    handleItem(product, 'delete', false, 0, 'wishlist')
  }

  const handleWishList = async (product: any | Array<any>) => {
    const accessToken = localStorage?.getItem('user')
    if (accessToken) {
      const createWishlist = async (product: any) => {
        try {
          await axios.post(NEXT_CREATE_WISHLIST, {
            id:
              user?.userId && user?.userId != EmptyGuid
                ? user?.userId
                : cartItems?.userId,
            productId: removedProduct?.length
              ? product?.productId.toLowerCase()
              : removedProduct?.productId.toLowerCase(),
            flag: true,
          })
          insertToLocalWishlist(product)
        } catch (error) {
          console.error(':: error ', error)
        }
      }

      if (removedProduct && removedProduct?.length) {
        removedProduct?.forEach((product: any) => {
          createWishlist(product)
        })
      } else if (removedProduct?.productId) {
        createWishlist(product)
      }
    } else {
      handleCloseRemoveModal(false)
      closeSidebar()
      localStorage.setItem(LocalStorage.Key.ROUTE_REDIRECTION_PATH, router.asPath)
      setTimeout(() => {
        openLoginView(window?.location?.href)
        setSidebarView('NEW_LOGIN_VIEW')
      }, 500)
      enableHtmlScroll()
    }

    let productAvailability = 'Yes'
    if (product?.currentStock > 0) {
      productAvailability = 'Yes'
    } else {
      productAvailability = 'No'
    }
    handleAllEvents({
      product,
      productAvailability,
      user,
      trackMoEngageEvent,
    })
  }

  return (
    <Transition.Root show={isRemoveProdConfirmationOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-99"
        onClose={() =>
          !isLoading.state ? handleCloseRemoveModal(false) : null
        }
      >
        <div className="fixed inset-0 left-0 bg-black/[.5]" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`fixed ${
                isMobileOnly ? 'top-full' : 'top-[35%] bottom-[65%]'
              } inset-x-0 right-0 flex max-w-full pl-0 pointer-events-none sm:pl-0`}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-200"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-200 sm:duration-200"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="w-screen pointer-events-auto">
                  <div className="relative flex flex-col h-full shadow-xl bg-black/[.5] z-99">
                    <div className="w-full h-auto max-w-md mx-auto bg-white center-fix-panel">
                      <div className="flex justify-between w-full px-8 py-3 bg-white border-b border-gray-100 shadow">
                        <h4 className="inline font-semibold text-black text-md">
                          Remove Item?
                        </h4>
                        <button
                          type="button"
                          className="inline p-0 text-black hover:text-black"
                          onClick={() => handleCloseRemoveModal(false)}
                          disabled={isLoading.state}
                        >
                          <span className="sr-only">{CLOSE_PANEL}</span>
                          <Cross className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>

                      <div className="flex flex-col w-full px-8 my-6">
                        {isChannelStore ? (
                          <p className="text-sm font-normal text-black">
                            Are you certain you don't want this product?
                          </p>
                        ) : (
                          <p className="text-sm font-normal text-black">
                            Are you certain you don't want this product? You may
                            move it to the wishlist and buy it later.
                          </p>
                        )}
                      </div>
                      <div className="flex justify-between gap-6 px-8 mb-6">
                        <button
                          type="button"
                          className="w-full h-12 sm:h-12 md:h-16 px-0 py-3 text-sm font-semibold text-red-500 bg-white border border-gray-300 sm:px-6 hover:border-red-200 hover:bg-red-200"
                          disabled={
                            (isLoading?.action === 'delete' &&
                              isLoading?.state) ||
                            (isLoading?.action === 'move-wishlist' &&
                              isLoading?.state)
                          }
                          onClick={async () => {
                            setIsLoading({ action: 'delete', state: true })
                            handleItem(removedProduct, 'delete')
                          }}
                        >
                          {isLoading?.action === 'delete' &&
                          isLoading?.state ? (
                            <LoadingDots />
                          ) : (
                            'Remove'
                          )}
                        </button>
                        {!isChannelStore && (
                          <button
                            disabled={
                              (isLoading?.action === 'move-wishlist' &&
                                isLoading?.state) ||
                              (isLoading?.action === 'delete' &&
                                isLoading?.state)
                            }
                            onClick={(e: any) => {
                              e.preventDefault()
                              setIsLoading({
                                action: 'move-wishlist',
                                state: true,
                              })
                              handleWishList(removedProduct)
                            }}
                            type="button"
                            className="w-full flex items-center justify-center p-3 -mr-0.5 font-semibold text-sm text-black opacity_092 border border-gray-300 rounded-sm hover:bg-gray-800 hover:text-white sm:px-6 hover:border-gray-900"
                          >
                            {isLoading?.action === 'move-wishlist' &&
                            isLoading?.state ? (
                              <LoadingDots />
                            ) : (
                              'Move to Wishlist'
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default RemoveItemPopup
